/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    img: "img"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "effective-relief-for-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#effective-relief-for-tinnitus",
    "aria-label": "effective relief for tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Effective relief for tinnitus"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An estimated 14 million Canadians have already experienced noise or ringing in the ears, or tinnitus, in the past year. Common triggers include acute hearing loss, excessive noise, impaired blood circulation, stress, or misalignment of the cervical spine or jaw joint. Learn more about the irritating ringing in your ears and the treatment options in our article about tinnitus."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus lasting at least three months is referred to as chronic. More than half of the people suffering from tinnitus also experience hearing loss. In those instances, the use of hearing systems with so-called tinnitus noisers can effectively relieve tinnitus and hearing loss. The tinnitus noiser counteracts the beeping and whistling by producing a signal that cancels out the distracting noises."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-tinnitus-noiser-provides-relief",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-tinnitus-noiser-provides-relief",
    "aria-label": "a tinnitus noiser provides relief permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A tinnitus noiser provides relief"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A tinnitus noiser produces a continuous noise that the patient perceives as pleasant. It is quieter than tinnitus itself and has a calming effect on the auditory system and consequently the neural pathway to the brain relaying auditory sensations. The brain has filter systems that categorize sounds as important or not important. After a while, due to the constant signal produced by the Noiser, both the Noiser and the tinnitus are categorized as not important. The result: tinnitus is not sensed anymore."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/tinnitus-dont-panic.png",
    alt: "Tinnitus don't panic when your ear is ringing",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-with-integrated-tinnitus-noisers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-with-integrated-tinnitus-noisers",
    "aria-label": "hearing aids with integrated tinnitus noisers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids with integrated tinnitus noisers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Examples of hearing aid devices with built-in tinnitus noisers include the Widex Clear 440, featuring the Zen-Noise function. Hearing aids with tinnitus noisers are also offered by the Signia Life and the Signia Pure Carat models. The hearing aids from the model series Audéo Q by Phonak also include tinnitus noisers. The ReSound Alera TS is a device made to address tinnitus exclusively. Moreover, Phonak offers a Smartphone tinnitus balance app, which allows access to a sound and music library, suitable for the treatment of tinnitus."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
